import React from "react"
import Layout from "../components/layout"
import Row from "../components/row"
import SEO from "../components/seo"

const vacaturesPage = () => (
  <Layout bigfoot={false}>
    <SEO title={"VACATURE: (junior) medewerker communicatie en marketing - DDCgroup"}
         description={"Wij zoeken een (junior) medewerker communicatie en marketing die een belangrijke en actieve rol gaat spelen bij het verder op de kaart zetten van DDC"}/>
    <Row size="small" classes="pt-200 pb-100" id="VacatureMarketing">
      <h1 className="uppercase t-30 bold mb-20 column col-12">
        VACATURE: (junior) medewerker communicatie en marketing
      </h1>
      <div className="hbox">
        <div className="column col-12">
          <p className="mb-20">
            Wij zoeken een (junior) medewerker communicatie en marketing die een belangrijke en actieve rol gaat spelen
            bij het verder op de kaart zetten van DDC. Je takenpakket is breed en je draagt zelf bij aan de invulling
            daarvan.
          </p>
          <p className="mb-20">
            Content is in communicatie cruciaal en jij bent de drijvende kracht in het creëren van informatie die
            DDC-ers aan je aanleveren. Jij vertaalt de ontvangen info naar goed leesbare en pakkende artikelen voor op
            onze website en social media. Je motiveert je collega’s tot het tijdig aanleveren van de juiste inhoud. Maar
            jouw creativiteit, mening en inzet is ook nodig om te komen tot allerlei activiteiten die de naamsbekendheid
            van DDC vergroten en gaan zorgen voor meer leads. Denk bijvoorbeeld aan organisatie van kennis-evenementen,
            produceren van marketing materialen, opzetten van een nieuwsbrief, communiceren met ons ad-words agency en
            afnemen van klant-interviews.
          </p>
          <p className="mb-20">
            Primaire werkplek: Ericsson campus, Rijen, Noord-Brabant <br/>
            32 - 40 uur per week
          </p>
          <p className="mb-20">
            Profiel:
            <ul>
              <li>In staat om zelfstandig te werken, neemt initiatief</li>
              <li>Kan goed luisteren, begrijpen en vertalen naar een sterke, begrijpelijke en pakkende tekst</li>
              <li>Georganiseerd</li>
              <li>Taakhouder, aanpakker; kan anderen aanzetten tot actie</li>
              <li>Sterk in Nederlandse taal</li>
              <li>Positief en verbinder</li>
              <li>Handig en ervaren met Social media en on-line communicatie</li>
            </ul>
          </p>
          <p className="mb-20">
            Voorbeeld van activiteiten:
            <ul>
              <li>Afnemen van klant interviews; schrijven en regelen</li>
              <li>Verspreiden van deze content op website, linkedin en andere kanalen</li>
              <li>Ervoor zorgen dat DDC-ers bijdragen aan regelmatig nieuwe berichten</li>
              <li>Herschrijven van door collega's aangeleverde marketing teksten, offertes etc.</li>
              <li>Teksten opmaken in het juiste formaat, voorzien van design en/of foto's</li>
              <li>Regelmatig nieuwsbrieven en artikelen (Linkedin, Facebook) opstellen en verspreiden</li>
              <li>Ondersteuning van organisatie van workshops of andere bijeenkomsten</li>
              <li>meedenken over nieuwe marketing activiteiten</li>
              <li>Ondersteuning bij alles wat nodig is voor deelname aan beurzen</li>
              <li>toepassen van door leveranciers beschikbaar gestelde tools/hulpmiddelen voor on-line marketing</li>
              <li>bijsturen Ad-words</li>
            </ul>
          </p>
          <p>Reageer naar <a href="mailto:info@ddcgroup.com" className="underlined">info@ddcgroup.com</a> of bel +31620488531</p>
        </div>
      </div>
    </Row>
    <Row size="small" classes="pt-100 pb-100 grey" id="VacatureTimextender">
      <h2 className="uppercase t-30 bold mb-20 column col-12">VACATURE: TimeXtender consultant</h2>
      <div className="hbox">
        <div className="column col-12">
          <p className="mb-20">
            Heb je sterke analytische vaardigheden? Hou jij van data integratie en data modeleren? Dan komen we graag met je in contact.
          </p>
          <p className="mb-20">
            <strong>DDC is opzoek naar een medior/senior TimeXtender / BI consultant.</strong>
          </p>
          <p className="mb-20">
            Opleidingsniveau: HBO of WO niveau
          </p>
          <p className="mb-20">
            We zoeken een consultant met de volgende achtergrond / kenmerken:
          <ul>
            <li>Communicatief</li>
            <li>Gedreven</li>
            <li>SQL kennis (minimaal medior level)</li>
            <li>TimeXtender ervaring is een pre</li>
            <li>Finance achtergrond is een pre</li>
          </ul>
          </p>
          <p className="mb-20">
            In deze rol is het van belang dat je de wensen van de klant nauwkeurig kunt vertalen naar een datamodel.<br/>
            Met de inzet van TimeXtender bouw je het datawarehouse en automatiseer je de data flow vanuit de bron tot de front-end applicaties (Power BI, Tableau of Qlik).<br/>
            Interesse? Stuur je bericht naar <a className="underlined" href="mailto:christian.pilon@ddcgroup.com">christian.pilon@ddcgroup.com</a> of bel 06-12427161<br/>
          </p>
        </div>
      </div>
    </Row>
  </Layout>
)

export default vacaturesPage
